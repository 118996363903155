module.exports = {
  pathPrefix: '/', // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "portfolio"
  siteTitle: 'Amorem Portfolio', // Navigation and Site Title
  siteTitleAlt: 'Amorem Portfolio - CTO - Full Stack React/NodeJS',
  siteDescription: 'Amorem Portfolio - CTO - Full Stack React/NodeJS',
  siteUrl: 'https://amorem.dev', // Domain of your site. No trailing slash!
  siteName: 'Amorem', // Name of Developer
  siteLanguage: 'fr', // Language Tag on <html> element
  siteLogo: './src/Assets/favicon.png', // Used for SEO and manifest
  siteImage: './src/Assets/siteImage.png', // Used for SEO and manifest

  // Manifest and Progress color
  manifestShortName: "Amorem's Portfolio",
  manifestBackgroundColor: '#fafafa',
  manifestThemeColor: '#79e9d2',
  manifestDisplay: 'standalone',

  // Social Meta data
  userTwitter: '@AmoremWeb', // Twitter Username
  ogSiteName: "Amorem's Portfolio", // Facebook Site Name
  ogLanguage: 'fr_FR',
  googleAnalyticsID: 'UA-85264567-7',

  toolsList: [
    'React',
    'Javascript ES6',
    'CSS',
    'Saas',
    'HTML',
    'GIT',
    'GraphQL',
    'Nodejs',
    'Wordpress',
    'Gatsby',
    'Netlify',
    'Nginx',
  ],
  skillsList: [
    'Website Performance',
    'SEO strategy',
    'Google Analytics',
    'Dedicated Server',
  ],
  Recommendations: [],
}
