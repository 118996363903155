import React from 'react'
import styles from './Footer.module.css'

function Footer(props) {
  const { ...restProps } = props

  return (
    <footer className={styles.footer}>
      <ul className={styles.footerLinks}>
        <li className="twitter">
          <a href="https://twitter.com/AmoremWeb" target="_blank" rel="noopener">
            Twitter
          </a>
        </li>
        <li className="github">
          <a href="https://github.com/amorem/" target="_blank" rel="noopener">
            Github
          </a>
        </li>
        <li className="Email">
          <a href="mailto:cedric@amorem.com" rel="noopener">
            Email
          </a>
        </li>

      </ul>

      {/* {<p className={styles.footerCopyright}>© 2019</p>} */}
    </footer>
  )
}

export default Footer
